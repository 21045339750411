export const elementHeight = (selector) => {
  if (!window) return null;
  if (!window.document) return null;
  const el = window.document.querySelector(selector);
  if (el) return el.clientHeight;
  return null;
};

export const cssCalcAvailableHeight = () => {
  const toolbarHeight = elementHeight(".v-toolbar");
  const footerHeight = elementHeight(".v-footer");
  if (toolbarHeight && footerHeight) {
    return `calc(100vh - ${toolbarHeight}px - ${footerHeight}px - 52px)`;
  }
  return null;
};
