<template>
  <v-row class="message-input justify-start align-center">
    <!-- ^quick messages dropdown -->
    <QuickMessagesMenu
      :items="computedQuickMessages"
      height="100%"
      @select="handleQuickMessageSelect"
    />
    <!-- $quick messages dropdown -->
    <!-- ^textarea - the actual message input -->
    <!-- TODO: We need to figure out how to make this more performant -->
    <v-col class="message-input__textarea-wrap pa-0">
      <v-textarea
        ref="messageInput"
        v-model="replyMessage"
        class="message-input__textarea"
        rows="1"
        height="50px"
        :maxlength="maxLengthAttribute"
        hide-details
        solo
        flat
        no-resize
        autofocus
        @keydown.enter.exact.stop.prevent
        @keyup.enter.exact="sendReplyMessage"
      />
      <span
        v-if="hasMaxCharLimit"
        class="message-input__max-char-label grey--text"
        v-text="maxCharDisplay"
      />
    </v-col>

    <v-col class="message-input__btn-group align-center pa-0 shrink">
      <EmojiPickerMenu @select="addEmoji" />

      <!-- ^send button -->
      <v-btn
        class="message-input__button"
        color="grey lighten-2 elevation-0 ma-0"
        :loading="loading"
        :disabled="disableSend"
        @click="sendReplyMessage"
      >
        <v-icon size="24" v-text="'$vuetify.icons.reply'"></v-icon>
      </v-btn>
      <!-- $send button -->
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import EmojiPickerMenu from "./EmojiPickerMenu.vue";

export default {
  name: "MessageThreadMessageInput",
  components: {
    EmojiPickerMenu,
    // TODO: should this be the EmojiPickerMenu component?
    // EmojiPickerMenu: () =>
    //   import(/* webpackChunkName: "emoji-mart" */ "emoji-mart-vue").then(
    //     ({ Picker }) => Picker
    //   ),
    QuickMessagesMenu: () => import("./QuickMessagesMenu.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    quickMessages: {
      type: Array,
      default: () => [],
    },
    showQuickMessages: {
      type: Boolean,
      default: true,
    },
    showEmojiPicker: {
      type: Boolean,
      default: true,
    },
    maxChars: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      replyMessage: "",
    };
  },
  computed: {
    ...mapGetters("stores", ["prewrittenTexts"]),
    disableSend() {
      return this.replyMessage.length < 1;
    },
    hasMaxCharLimit() {
      return this.maxChars > 0;
    },
    maxCharDisplay() {
      if (!this.hasMaxCharLimit) return "";
      return `${this.replyMessage.length}/${this.maxChars}`;
    },
    maxLengthAttribute() {
      return this.maxChars > 0 ? this.maxChars : "";
    },
    computedQuickMessages() {
      return (
        (this.quickMessages.length > 0 && this.quickMessages) ||
        this.prewrittenTexts ||
        []
      );
    },
  },
  methods: {
    // Set the message to the quick message content, then focus the input
    handleQuickMessageSelect({ message }) {
      this.replyMessage = message;
      this.$refs.messageInput.focus();
    },
    // Add the emoji to the message, then focus the input
    addEmoji(emoji) {
      this.replyMessage += emoji.native;
      this.$refs.messageInput.focus();
    },
    // Handler for clicking the "send" button
    sendReplyMessage() {
      if (this.replyMessage.length < 1) return false;
      this.$emit("send", this.replyMessage);
      this.reset();
    },
    reset() {
      this.$nextTick(() => {
        this.replyMessage = "";
      });
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.message-input {
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: white;
  margin-left: 0;
  margin-right: 0;

  .message-input__textarea .v-text-field__slot textarea {
    height: 50px;
    margin: 0 !important;
    padding: 5px 0 0 0;
  }
  .message-input__max-char-label {
    position: absolute;
    right: 150px;
    bottom: 0;
    font-size: 0.75em;
  }

  .message-input__btn-group {
    display: flex;
    .message-input__button {
      height: 50px;
      margin: 0;
      &.message-input__emoji-button {
        min-width: 50px;
      }
    }
  }
}
</style>
