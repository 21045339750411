<template>
  <div
    ref="messageThreadRoot"
    class="message-thread rai-text-message-list"
    :style="messageThreadStyle"
  >
    <div
      ref="messageThread"
      class="pa-4 message-thread__content rai-text-message-list__content overflow-y-auto rai-scrollbar"
      :style="messageThreadContentStyle"
    >
      <div v-for="date in Object.keys(groupedMessages)" :key="date">
        <div
          class="rai-text-message-list__date secondary--text text-center mt-4"
        >
          <strong v-text="date" />,
          <span v-text="groupedMessages[date][0].chatTime" />
        </div>
        <template v-for="message in groupedMessages[date]">
          <div
            :key="message.id"
            ref="messageList"
            :class="`rai-text-message rai-text-message__${
              message.msgType || message.msg_type
            }`"
          >
            <v-row
              :justify="justification(message.msgType || message.msg_type)"
            >
              <MessageThreadMessage v-if="message.body" :message="message" />
            </v-row>
            <v-row
              v-for="url in message.mediaUrls"
              :key="url"
              :justify="justification(message.msgType || message.msg_type)"
            >
              <v-img :src="url" class="rai-text-message__image" />
            </v-row>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defer, groupBy, upperFirst } from "lodash";
import { materialDate } from "@/utils/datetime";
import MessageThreadMessage from "./MessageThreadMessage";

import {
  format,
  isBefore,
  isSameDay,
  isAfter,
  subDays,
  isSameYear,
  parseISO,
} from "date-fns";

export default {
  name: "MessageThread",
  components: { MessageThreadMessage },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    quickMessages: {
      type: Array,
      default: () => [],
    },
    maxChars: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: "100%",
    },
    rootElementHeight: {
      type: String,
      default: undefined,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // localHeight: 'calc(100vh - 52px - 64px - 48px)'
    };
  },
  computed: {
    messages() {
      return this.items;
    },
    groupedMessages() {
      return groupBy(
        this.messages.map((m) => ({
          ...m,
          chatDate: upperFirst(materialDate(m.createdAt || m.created_at)),
          chatTime: format(
            parseISO(m.createdAt) || parseISO(m.created_at),
            "h:mm a"
          ),
        })),
        "chatDate"
      );
    },
    messageThreadContentStyle() {
      const style = {
        height: this.height,
      };
      if (this.bordered) {
        return Object.assign({}, style, {
          border: "1px solid rgba(0, 0, 0, 0.2)",
        });
      }
      return style;
    },
    messageThreadStyle() {
      if (
        this.rootElementHeight !== null &&
        this.rootElementHeight !== undefined
      ) {
        return {
          height: this.rootElementHeight,
        };
      }

      return null;
    },
  },
  watch: {
    items() {
      this.scrollToLastMessage();
    },
  },
  mounted() {
    //localhost:8080/daybook
    http: this.initialize();
  },
  methods: {
    justification(type) {
      return {
        sent: "end",
        received: "start",
        info: "center",
      }[type];
    },
    scrollToLastMessage() {
      this.$nextTick(() => {
        defer(() => {
          this.$refs.messageThread.scrollTop =
            this.$refs.messageThread.scrollHeight;
        });
      });
    },
    sendMessage(m) {
      this.$emit("send", m);
    },
    initialize() {
      this.scrollToLastMessage();
    },
    chatDate(date) {
      const today = new Date();
      const fiveDaysAgo = subDays(today, 5);
      if (!isSameYear(date, today)) return format(date, "MMM d, yyyy");
      if (isSameDay(date, today)) return "Today";
      if (isAfter(date, fiveDaysAgo)) return format(date, "iiii");
      if (isBefore(date, fiveDaysAgo)) return format(date, "MMM d");
      return date;
    },
  },
};
</script>

<style lang="scss">
.rai-text-message__content {
  border-radius: 16px;
  margin-bottom: 8px;
  font-size: 14px;
}
.rai-text-message__sent {
  .rai-text-message__content {
    background-color: var(--v-info-lighten3);
    color: var(--v-info-darken4);
  }
}
.rai-text-message__received {
  .rai-text-message__content {
    background-color: var(--v-ui-lighten3);
  }
}
</style>
