<template>
  <div v-if="message && message.mediaUrls && message.mediaUrls.count > 0">
    <div class="rai-text-message__content">
      <v-tooltip v-if="messageStatus === 'error'" top>
        <template #activator="{ on }">
          <span class="rai-text-message__send_error mr-2" v-on="on"
            ><v-icon color="error" v-text="'$vuetify.icons.warning'"></v-icon
          ></span>
        </template>
        <span v-text="message.sendErrorMessage" />
      </v-tooltip>

      <!-- eslint-disable vue/no-v-html -->
      <span v-html="body" />
      <!--eslint-enable-->
    </div>

    <v-img
      v-for="url in message.mediaUrls"
      :key="url"
      :src="url"
      max-width="60%"
      class="rai-text-message__image"
    />
  </div>

  <div v-else class="rai-text-message__content text-body-2 py-2 px-6 mt-4 mb-4">
    <v-tooltip v-if="messageStatus === 'error'" top>
      <template #activator="{ on }">
        <span class="rai-text-message__send_error mr-2" v-on="on"
          ><v-icon color="error" v-text="'$vuetify.icons.warning'"></v-icon
        ></span>
      </template>
      <span v-text="message.sendErrorMessage" />
    </v-tooltip>
    <!-- eslint-disable vue/no-v-html -->
    <span v-html="message.body" />
    <!--eslint-enable-->
  </div>
</template>

<script>
import {
  isSameDay,
  isBefore,
  isAfter,
  format,
  subDays,
  parseISO,
} from "date-fns";
export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    type() {
      return this.message.msg_type || this.message.msgType;
    },
    id() {
      return this.message.id;
    },
    wasSent() {
      return this.type === "sent";
    },
    wasReceived() {
      return this.type === "received";
    },
    isInfo() {
      return this.type === "info";
    },
    createdAt() {
      return (
        parseISO(this.message.created_at) || parseISO(this.message.createdAt)
      );
    },

    dynamicClasses() {
      return {
        "rai-text-message": true,
        "rai-text-message--sent": this.wasSent,
        "text-message--received rai-text-message--received": this.wasReceived,
        "info-message rai-text-message--info": this.isInfo,
        "grey--text": this.isInfo,
        "text-center": this.isInfo,
      };
    },
    contentClasses() {
      return {
        "text-message__content": !this.isInfo,
        "grey--text": this.isInfo,
      };
    },
    chatDate() {
      let ret = {
        day: "",
        time: "",
      };
      let now = new Date();
      let fiveDaysAgo = subDays(now, 5);

      if (isSameDay(now, this.createdAt)) {
        ret.day = "Today";
      }
      if (isAfter(this.createdAt, fiveDaysAgo)) {
        ret.day = format(this.createdAt, "iiii");
      }
      if (isBefore(this.createdAt, fiveDaysAgo)) {
        ret.day = format(this.createdAt, "iiii, MMM d,");
      }
      ret.time = format(this.createdAt, "h:mm a");
      return ret;
    },
    messageStatus() {
      if (this.message.msgType === "sent") {
        if (
          this.message.sendStatus === "rejected" ||
          this.message.sendStatus === "failed"
        ) {
          return "error";
        }
      }
      return this.message.msgType;
    },
  },
  methods: {
    shouldDisplayBreak(a, line) {
      let ret = false;
      if (a.indexOf(line) !== a.length - 1) {
        ret = true;
      }
      return ret;
    },
  },
};
</script>
