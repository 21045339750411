<template>
  <div v-if="isLoading">
    <v-progress-linear
      :indeterminate="true"
      color="accent"
      class="pa-0 ma-0"
      height="2"
    />
  </div>

  <component
    :is="reactiveComponent"
    v-else-if="messageThread"
    :title="messageThread.name || formatNumber(messageThread.cellphone)"
    :hide-toolbar="inDialog"
  >
    <MessageThread
      :items.sync="messageThread.messages"
      :height="threadHeight"
      :root-element-height="rootElementHeight"
      :quick-messages="$store.getters['stores/prewrittenTexts'] || []"
    />
    <template v-if="threadId" #form>
      <!-- TODO: switch to new component
        <rai-text-field placeholder="Reply to message" v-bind:value.sync="newMessage"></rai-text-field>
            -->
      <MessageInput :loading="queryLoading" @send="send" />
    </template>
  </component>
</template>

<script>
import { mapMutations } from "vuex";
import { hasSnackbarAccess } from "@/mixins/ui";
import { formatNumber } from "@/utils/phonenumber";
import { cssCalcAvailableHeight } from "@/utils/dom";
import {
  addMessageToThreadCache,
  MESSAGE_CREATE,
  MESSAGE_SENT_SUBSCRIPTION,
  MESSAGE_THREAD,
} from "./graphql";

import MessageThread from "./components/MessageThread.vue";
import MessageInput from "./components/MessageThreadMessageInput.vue";

export default {
  name: "ShowThreadView",
  components: {
    MessageThread,
    MessageInput,
    DetailViewMobile: () => import("./components/DetailViewMobile.vue"),
    DetailViewDesktop: () => import("./components/DetailViewDesktopGrid.vue"),
  },
  mixins: [hasSnackbarAccess],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    threadId: {
      type: [Number, String],
      required: true,
    },
    inDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "Texting",
      scrollTop: 0,
      queryLoading: false,
      isLoading: 0,
    };
  },
  apollo: {
    messageThread: {
      query: MESSAGE_THREAD,
      variables() {
        return {
          storeId: this.storeId,
          id: parseInt(this.threadId),
        };
      },
      skip() {
        return !this.storeId || !this.threadId;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      update(response) {
        return response.messageThread;
      },
    },
    $subscribe: {
      textingMessageSent: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        variables() {
          return {
            storeId: this.storeId,
            messageThreadId: this.threadId,
          };
        },
        skip() {
          return !this.storeId || !this.threadId;
        },
        result(response) {
          addMessageToThreadCache(
            // Important - we need to send this.$apollo.getClient() and not this.$apollo.getClient().cache.
            //  - if we send cache -> the cache will not actually update.
            this.$apollo.getClient(),
            response.data.textingMessageSent.message,
            this.threadId
          );
        },
      },
    },
  },
  computed: {
    reactiveComponent() {
      return this.$vuetify.breakpoint.smAndUp
        ? "DetailViewDesktop"
        : "DetailViewMobile";
    },
    variables() {
      return {
        storeId: this.storeId,
        id: parseInt(this.threadId),
      };
    },
    threadHeight() {
      if (this.inDialog) {
        return "100%";
      }

      const threadHeight = this.$vuetify.breakpoint.mdAndUp ? "64" : "48";
      return `calc(100vh - 64px - ${threadHeight}px - 48px)`;
    },
    rootElementHeight() {
      if (this.inDialog) {
        return "100%";
      }

      return null;
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    cssCalcAvailableHeight,
    formatNumber,
    async send(body) {
      this.queryLoading = true;
      const msgVariables = {
        input: {
          messageThreadId: this.variables.id.toString(),
          body,
        },
      };
      try {
        await this.$apollo.mutate({
          mutation: MESSAGE_CREATE,
          variables: msgVariables,
          update: async (
            store,
            {
              data: {
                createMessage: { message },
              },
            }
          ) => {
            addMessageToThreadCache(
              store,
              message,
              this.variables.id.toString()
            );
          },
        });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while sending message. ${error}`,
        });
      }
      this.queryLoading = false;
    },
  },
};
</script>
